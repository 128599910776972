<script>
import './fonts.js'
import 'uloc-vue/dist/uloc.styl'
import 'uloc-vue-auth/assets/styl/app.styl'
import 'uloc-vue-plugin-erp/assets/styl/app.styl'
import 'uloc-vue-plugin-erp/assets/styl/icons.styl'
import {ULayout, UPageContainer, UProgress} from 'uloc-vue'
import {ErpLayoutBase} from 'uloc-vue-plugin-erp'
import menu from './menu'
import {createComunicator} from './plugins/comunicator'
import FixAlert from './components/layout/components/FixAlert'
import http from '@/utils/services/http'
import {testRoles, testAcls} from '@/utils/security'
import {poslogin, prelogin} from "@/domain/globalconfig/services"
import Vue from "vue"
import ErpPosMenu from '@/components/layout/components/PosMenu'
import WorkMixin from "@/mixins/WorkMixin"
import {background} from "@/domain/stats/service"
import {worker} from "@/domain/global"
import {erpDefs} from "@/definitions"

Vue.prototype.testRoles = testRoles
Vue.prototype.testAcls = testAcls

Vue.prototype.$appConfig = {}
Vue.prototype.$globalConfig = {}
Vue.prototype.$appConfig.testConfig = function (c) {
  return Vue.prototype.$globalConfig && Vue.prototype.$globalConfig[c] && Vue.prototype.$globalConfig[c].value !== null && Vue.prototype.$globalConfig[c].value !== 'null'
}
Vue.prototype.$appConfig.getConfig = function (c) {
  return Vue.prototype.$globalConfig[c] ? Vue.prototype.$globalConfig[c].value : null
}
Vue.prototype.$appConfig.isConfigTrue = function (c) {
  if (typeof Vue.prototype.$globalConfig[c] !== 'undefined' && typeof Vue.prototype.$globalConfig[c].value !== 'undefined') {
    const value = Vue.prototype.$globalConfig[c].value
    return value === '1' || value === 1 || value === true || value === 'true' || value === 's' || value === 'y'
  }
  return false
}
Vue.prototype.$appConfig.isConfigNotEmpty = function (c) {
  if (typeof Vue.prototype.$globalConfig[c] !== 'undefined' && typeof Vue.prototype.$globalConfig[c].value !== 'undefined') {
    const value = Vue.prototype.$globalConfig[c].value
    return value !== '0' && value !== 'null' && value !== 'NULL' && value !== null && value !== '' && value !== ' '
  }
  return false
}

export default {
  name: 'AppRoot',
  provide: function () {
    return {
      appRoot: this
    }
  },
  mixins: [WorkMixin],
  components: {FixAlert, ULayout, UPageContainer, ErpLayoutBase, ErpPosMenu, UProgress},
  data() {
    return {
      installed: false,
      setupProgress: 0
    }
  },
  computed: {
    menu() {
      let bensVersion = 1
      if (this.$appConfig.isConfigTrue('bem.tipoGestao')) {
        console.log('%c Gestão de Bens Avançada!', 'font-weight: bold; font-size: 30px;color: white; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)')
        bensVersion = 2
      }
      let _menu = []
      menu.map(m => {
        this.testMenuRoles(m, _menu)
      })
      _menu.map(menu => {
        let components = []
        menu.components.map(c => {
          this.testMenuRoles(c, components)
        })
        menu.components = components

        menu.components.map(c => {
          let features = []
          c.features.map(f => {
            if (bensVersion === 2 && f && f.href) {
              f.href = f.href.replace('/#/bens/', '/#/bens2/')
            }
            this.testMenuRoles(f, features)
          })
          c.features = features
        })
        return menu
      })
      return _menu
    },
    session() {
      console.log('Session', this.$uloc.auth.session.user)
      return {
        ...this.$uloc.auth.session.user
      }
    }
  },
  methods: {
    async start () {
      try {
        await worker()
      } catch (e) {
        console.error(e)
      }
      this.testVersion()
      this.$subscribeInterval = setInterval(() => {
        console.log('Tentando inscrever')
        if (this.subscribe()) {
          clearInterval(this.$subscribeInterval)
        }
      }, 1000)
      // Add a response interceptor
      http.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      }, (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response && error.response.data && error.response.data.error && error.response.data.error === 'authorization') {
          console.log(error)
          /*this.$uloc.dialog({
            title: 'Você não tem permissão de acesso',
            message: error.response.config.url
          })*/
        }
        return Promise.reject(error);
      });
      this.$installInterval = setInterval(() => {
        const progress = this.setupProgress * 100
        if (progress >= 100) {
          clearInterval(this.$installInterval)
        }
        this.setupProgress = (progress > 50 ? (progress + 5) : (progress + 1))
      }, 1)

      this.$checkPublicConfig = setInterval(() => {
        if (this.$globalConfig && this.$globalConfig.clientId) {
          clearInterval(this.$checkPublicConfig)
        }
        this.fetchPublicConfig()
      }, 5000)
      this.fetchPublicConfig()

      addEventListener('online',  this.updateOnlineStatus)
      addEventListener('offline', this.updateOnlineStatus)
      setTimeout(() => {
        this.updateOnlineStatus()
        //window.setStatsEndpoint(process.env.VUE_APP_STATS)
      }, 1000)
      this.$posloginInterval = setInterval(() => {
        if (erpDefs.loaded) {
          this.$posloginInterval && clearInterval(this.$posloginInterval)
          return
        }
        this.fetchPoslogin()
      }, 2000)
    },
    connectComunicator() {
      createComunicator.call(this)
      this.comunicator.on('com/connect', (env) => {
        //this.$refs.fixAlert.showCenter(false)
        this.subscribe()
      })
      this.comunicator.on('com/disconnect', (env) => {
        // this.$refs.fixAlert && this.$refs.fixAlert.showCenter(true, 'Conexão com Realtime perdida, aguarde ou comunique o administrador')
      })
    },
    subscribe () {
      if (this.$globalConfig && this.$globalConfig.clientId && this.comunicator && this.comunicator.subscribe) {
        console.log('Executando inscrição.')
       return this.comunicator.subscribe(this.$globalConfig.clientId)
      }
      return false
    },
    testVersion() {
      console.log('Testando versão do sistema', this.$uloc.auth)
      const goSetup = () => {
        this.$nextTick(() => {
          setTimeout(() => {
            //this.$router.push('/setup')
          }, 1000)
        })
      }
      if (!this.$uloc.auth.logged || !this.$uloc.auth.session) return
      if (!this.$uloc.auth.session.user.config) {
        goSetup()
      } else if (String(this.$uloc.auth.session.user.config.business['appVersion']) !== String(this.$uloc.auth.session.user.config.business.businessAppVersion)) {
        goSetup()
      } else {
        console.log('Sistema encontra-se na versão mais recente ', this.$uloc.auth.session.user.config.business['appVersion'], this.$uloc.auth.session.user.config.business.businessAppVersion)
      }

    },
    updateOnlineStatus(event) {
      if (navigator) {
        const css = "app-connection-offline"
        if(!navigator.onLine) {
          !document.body.classList.contains(css) && document.body.classList.add(css)
          //this.$refs.fixAlert && this.$refs.fixAlert.showCenter(true, 'Você está sem internet, verifique sua conexão.')
        } else {
          //document.body.classList.contains(css) && document.body.classList.remove(css)
          //this.$refs.fixAlert.showCenter(false)
        }
      }
    },
    defineUserToStats () {
      if (!this.$uloc.auth.logged || !this.session) return
      window.SL_USER = {
        userId: this.$uloc.auth.session.user.id,
        personId: this.$uloc.auth.session.user.person,
        personName: this.$uloc.auth.session.user.name
      }
    },
    defineBackgroundServices () {
      if (!this.$uloc.auth.logged || !this.session) return
      this.$intervalBackground && clearInterval(this.$intervalBackground)
      if (!this.$intervalBackground) {
        // this.backgroundServices()
      }
      this.$intervalBackground = setInterval(() => {
        // this.backgroundServices()
      }, 30000)
    },
    backgroundServices () {
      //return;
      console.log('Execute background services...')
      background().then(r => {
        console.log(r.data)
      }).catch(e => {
        console.log(e)
      })
    },
    fetchPublicConfig () {
      prelogin()
          .then(response => {
            //TODO: Implementar se usuário foi bloqueado
            //TODO: Implementar se usuário está inativo
            if (!response || !response.data) {
              this.installed = true
              return
            }
            if (response.data['sistema.version'] && this.$uloc.auth.logged && this.$uloc.auth.session && this.$uloc.auth.session.user && this.$uloc.auth.session.user.config) {
              this.$uloc.auth.session.user.config.business['appVersion'] = response.data['appVersion']
            }
            if (typeof response.data['erp.login.css'] !== 'undefined' && response.data['erp.login.css'].value) {
              const sheet = document.createElement('style')
              console.log('Aplicando folha de estilo no ERP')
              sheet.innerHTML = response.data['erp.login.css'].value
              document.body.appendChild(sheet)
              delete response.data['erp.login.css']
            }
            if (typeof response.data['empresa.favicon'] !== 'undefined' && response.data['empresa.favicon'].value && response.data['empresa.favicon'].value !== 'null' && response.data['empresa.favicon'].value !== '0') {
              const favicon = document.getElementById('app-favicon')
              if (favicon) {
                favicon.href = response.data['empresa.favicon'].value
              }
            }
            setTimeout(() => {
              if (this.$uloc.auth.logged) {
                if (response.data['appVersion']) {
                  console.log('Versão cloud', response.data['appVersion'])
                  this.testVersion()
                }
              }
            }, 1500)
            console.log(response)
            this.config = response.data
            Vue.set(Vue.prototype, '$globalConfig', this.config)
            // Vue.prototype.$globalConfig = this.config
            this.$nextTick(() => {
              setTimeout(() => {
                this.$installInterval && clearInterval(this.$installInterval)
                this.loader = true
                this.installed = true
                this.$uloc.auth.logged && !this.comunicator && this.connectComunicator()
                this.$uloc.auth.logged && this.defineBackgroundServices()
                if (response.data['user']){
                  const dtUser = response.data['user']
                  console.group('User Roles')
                  console.log('%cSL USER ROLES FOR ERP:', 'font-size: 14px; color: red;');
                  Array.isArray(dtUser.roles) && dtUser.roles.forEach(role => {
                    console.log('%c' + role, 'font-size: 12px; color: green;')
                  })
                  console.log('++++++++++')
                  console.log('%cSL USER ACL FOR ERP:', 'font-size: 14px; color: yellow;');
                  Array.isArray(dtUser.acl) && dtUser.acl.forEach(acl => {
                    console.log('%c' + acl, 'font-size: 12px; color: green;')
                  })
                  console.groupEnd()
                  this.$uloc.auth.session.user.roles = dtUser.roles
                  this.$uloc.auth.session.user.acl = dtUser.acl
                  this.$uloc.auth.session.user.papeis.roles = dtUser.roles
                  this.$uloc.auth.session.user.papeis.acl = dtUser.acl
                }
              }, 100)
            })
          })
          .catch(error => {
            this.loader = true
            this.installed = true
            setTimeout(() => {
              this.fetchPublicConfig()
            }, 5000)
            console.log(error)
          })
    },
    fetchPoslogin () {
      try {
        if (!this.$uloc.auth.logged || !this.$uloc.auth.session) return
        erpDefs.loaded = true
        poslogin()
            .then(({data}) => {
              if (data.invoices) {
                erpDefs.invoices = data.invoices.result
                if (data.invoices.financialBlock) {
                  erpDefs.financialBlock = data.invoices.financialBlock
                }
              }
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }
      catch (e) {
        console.error(e)
      }
    },
    testMenuRoles (m, _menu) {
      let enabled = false
      if (m.roles) {
        enabled = testRoles(m.roles, this.$uloc.auth.session.user.roles)
      }
      if (!enabled && m.acl) {
        let acl = m.acl
        if (!Array.isArray(acl)) {
          acl = acl()
        }
        enabled = testAcls(acl, this.$uloc.auth.session.user.acl, this.$uloc.auth.session.user.roles)
      }
      if (!enabled && m.activeConfig) {
        enabled = this.testConfig(m.activeConfig)
      }
      if (!m.roles && !m.acl && !m.activeConfig) {
        enabled = true
      }
      enabled && _menu.push(m)
    }
  },
  created() {
    this.start()
  },
  mounted() {
    setTimeout(() => {
      //SLEventClient && SLEventClient('load-page', 'erp', null, window && window.location.href || document && document.URL, this.$uloc.auth.logged && this.session && this.session.id)
      this.defineUserToStats()
    }, 2000)
  },
  watch: {
    'session'(v) {
      console.log(v)
    },
    '$uloc.auth.logged'(v) {
      if (v) {
        if (!this.$uloc.auth.session.user.roles.includes('ROLE_ERP') && !this.$uloc.auth.session.user.roles.includes('ROLE_COMITENTE')) {
          this.$uloc.notify('Você não tem permissão para acessar esta área.')
          this.$router.push('/logout')
        }
        this.defineUserToStats()
        this.testVersion()
        this.connectComunicator() //
        this.defineBackgroundServices()
        this.fetchPoslogin()
      } else {
        // disconnect
        console.log('Disconnect', this.comunicator)
        this.comunicator && this.comunicator.close()
      }
    }
  }
}
</script>

<template>
  <div v-if="$uloc.auth.logged && session" id="uloc-app" class="theme-white">
    <fix-alert ref="fixAlert"/>
    <!--<pre style="min-height: 200px; position: absolute; z-index: 1000; background-color: #FFFFFF">
      {{$uloc.auth.session.user.roles}}
    </pre>-->
    <u-layout v-if="installed" ref="layout">

      <u-page-container>

        <ErpLayoutBase :menu-data="menu" :default-active-menu="menu[0].id" :user="session">
          <template v-slot:posmenu>
            <erp-pos-menu ref="posmenu"/>
          </template>
          <router-view/>
        </ErpLayoutBase>

      </u-page-container>
    </u-layout>
    <div v-else style="position: absolute; display: inline-flex; left: 0; right: 0; top: 0; bottom: 0; align-items: center; justify-content: center; font-family: Arial, Helvetica, sans-serif; padding: 50px">
      <div id="application-preloader">
        <div style="text-align:center">
          <svg id="coffe-svg" class="tea" width="37" height="48" viewbox="0 0 37 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.0819 17H3.02508C1.91076 17 1.01376 17.9059 1.0485 19.0197C1.15761 22.5177 1.49703 29.7374 2.5 34C4.07125 40.6778 7.18553 44.8868 8.44856 46.3845C8.79051 46.79 9.29799 47 9.82843 47H20.0218C20.639 47 21.2193 46.7159 21.5659 46.2052C22.6765 44.5687 25.2312 40.4282 27.5 34C28.9757 29.8188 29.084 22.4043 29.0441 18.9156C29.0319 17.8436 28.1539 17 27.0819 17Z" stroke="var(--secondary)" stroke-width="2"></path>
            <path d="M29 23.5C29 23.5 34.5 20.5 35.5 25.4999C36.0986 28.4926 34.2033 31.5383 32 32.8713C29.4555 34.4108 28 34 28 34" stroke="var(--secondary)" stroke-width="2"></path>
            <path id="teabag" fill="var(--secondary)" fill-rule="evenodd" clip-rule="evenodd" d="M16 25V17H14V25H12C10.3431 25 9 26.3431 9 28V34C9 35.6569 10.3431 37 12 37H18C19.6569 37 21 35.6569 21 34V28C21 26.3431 19.6569 25 18 25H16ZM11 28C11 27.4477 11.4477 27 12 27H18C18.5523 27 19 27.4477 19 28V34C19 34.5523 18.5523 35 18 35H12C11.4477 35 11 34.5523 11 34V28Z"></path>
            <path id="steamL" d="M17 1C17 1 17 4.5 14 6.5C11 8.5 11 12 11 12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke="var(--secondary)"></path>
            <path id="steamR" d="M21 6C21 6 21 8.22727 19 9.5C17 10.7727 17 13 17 13" stroke="var(--secondary)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
          <u-progress
              :percentage="setupProgress"
              color="positive"
              height="6px"
              style="border-radius: 5px; width: 60%; max-width: 300px; margin:5px auto"></u-progress>
          <div style="font-size: 12px; color: #666666; font-family: monospace, arial; margin-top: 5px">Aquecendo a aplicação para você</div>
          <div style="font-size: 10px; color: #666666; font-family: monospace, arial; margin-top: 2px">Powered by InnLei</div>
        </div>
      </div>
    </div>

  </div>
  <div class="flex full-height full-width justify-between" v-else>
    <router-view v-if="$route.name === 'login'"/>
    <div v-else class="wrapper">Saindo...</div>
  </div>
</template>

<style src="./assets/style.styl" lang="stylus"></style>
