// import Main from './Main'
import ListaArrematantes from './components/ListaArrematantes'
import ExportarBase from './components/ExportarBase'
const Monitor = () => import('./components/Monitor')
const Aprovacao = () => import('./components/MonitorAprovacao')
const Habilitacao = () => import('./components/MonitorHabilitacao')

export default [
  // { path: '/arrematantes', component: Main, name: 'arrematantes.main' },
  {path: '/leiloeiros/lista', component: ListaArrematantes, name: 'arrematantes.lista'},
  {path: '/leiloeiros/exportar', component: ExportarBase, name: 'arrematantes.exportar'},
  {path: '/leiloeiros/monitor', component: Monitor, name: 'arrematantes.monitor'},
  {path: '/leiloeiros/aprovacao', component: Aprovacao, name: 'arrematantes.aprovacao'},
  {path: '/leiloeiros/habilitacao', component: Habilitacao, name: 'arrematantes.habilitacao'},
]
